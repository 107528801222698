import { NotificationCardI, NotificationI } from "@/interfaces/Notifications";
import "./CommentReply.scss";
import { getDateFormat2 } from "@/utils/DateFormats";
import { useUserContext } from "@/context/UserContext";
import { handleImageError } from "@/utils/GalleryFunctions";
import Link from "next/link";

export function CommentReply(props: NotificationCardI) {
  const { userToken } = useUserContext();
  const { notification } = props;

  const handleNotificationClick = async () => {
    // let response = await setNotificationSeen(userToken, notification._id);
    // if (response.status === "Success") {
    // 	notification.isNewElement = false;
    // }
    // navigate("/gallery/" + notification.gallery_id);
  };
  return (
    <Link
      href={`/gallery/${notification.payload.photo?.collectionId}?p=${
        notification.payload.photo?._id
      }&year=${notification.payload.photo?.year}&v=${
        notification.type === "commentReply" ? 1 : 2
      }`}
    >
      <div className="notification-card-container">
        <div className="title-seen">
          <div>
            <h5>{notification.payload.title}</h5>
            <p className="m-0 stem-light">{notification.payload.message}</p>
          </div>
          <span>
            {notification.isNewElement ? (
              <div className="seen-check"></div>
            ) : (
              ""
            )}
          </span>
        </div>
        <h6>{getDateFormat2(notification.createdAt)}</h6>
        <div className="nc-image-container" onClick={handleNotificationClick}>
          <img
            src={notification.payload?.photo.url}
            onError={handleImageError}
          ></img>
        </div>
      </div>
    </Link>
  );
}
