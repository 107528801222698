"use client";
import Link from "next/link";
import { usePathname } from "next/navigation";
import React from "react";
const plusIcon = "../../assets/images/icons/plus-icon.svg";
export const PlusIcon = () => {
  const pathname = usePathname();
  return (
    <Link href={"upload"}>
      <img
        src={plusIcon}
        className={`upload-image-btn ${
          pathname.includes("gallery") ? "d-none" : ""
        }`}
        role="button"
      />
    </Link>
  );
};
