import {
	addMonths,
	addYears,
	differenceInDays,
	differenceInMonths,
	differenceInYears,
	format,
	parse,
	formatDistance,
	isToday,
	isYesterday,
	parseISO,
} from "date-fns";

// the following function accepts an string date in the following format: MM/dd/yyyy
// to convert it to this format: yyyy-MM-dd
export function getDateFormat1(dateString: string) {
	if (dateString) {
		const date = parse(dateString, "MM/dd/yyyy", new Date());
		const formattedDate = format(date, "yyyy-MM-dd");
		return formattedDate;
	} else {
		return " - ";
	}
}

export function getDateFormat2(date: number) {
	// This function takes a date in number format and returns mm/dd/yyyy format
	let tempDate = new Date(date);
	const month = String(tempDate.getMonth() + 1).padStart(2, "0");
	const day = String(tempDate.getDate()).padStart(2, "0");
	const year = tempDate.getFullYear();

	return `${month}/${day}/${year}`;
}

export function getPhotoDate(year: string, month: string, day: string) {
	return `${year}-${month}-${day}`;
}

export function readOnlyDateFormat(date: Date) {
	return format(date, "MMM dd, yyyy, h:mm:ss a");
}

export function getDifferenceDateBirth(photoDate: string, birthDate: string) {
	const pastDate = new Date(birthDate);
	const now = new Date(photoDate); // today's date

	const years = differenceInYears(now, pastDate);
	const months = differenceInMonths(now, addYears(pastDate, years));
	const days = differenceInDays(now, addMonths(addYears(pastDate, years), months));

	const differenceDate = `${years} years, ${months} months, ${days} days`;

	return differenceDate;
}

export function commentDate(dateString: string) {
	const date = parseISO(dateString);
	const now = new Date();

	if (isToday(date)) {
		return "Today";
	}

	if (isYesterday(date)) {
		return "Yesterday";
	}

	return formatDistance(date, now) + " ago";
}
