import { NotificationI } from "@/interfaces/Notifications";
import { handleImageError } from "@/utils/GalleryFunctions";
import { getDateFormat2 } from "@/utils/DateFormats";

interface GeneralPropsI {
  notification: NotificationI;
}

function General(props: GeneralPropsI) {
  const { notification } = props;
  return (
    <div className="notification-card-container w-100">
      <div className="title-seen">
        <div>
          <h5>{notification.payload.title}</h5>
          <p className="m-0 stem-light" style={{ whiteSpace: "pre-wrap" }}>
            {notification.payload.message}
          </p>
        </div>
        <span>
          {notification.isNewElement ? <div className="seen-check"></div> : ""}
        </span>
      </div>
      <h6>{getDateFormat2(notification.createdAt)}</h6>
      <div className="nc-image-container">
        <img src={notification.payload?.photo} onError={handleImageError}></img>
      </div>
    </div>
  );
}

export default General;
