import { useRouter } from "next/navigation";
import { useEffect } from "react";

function ScrollToTop() {
  // Use the useScroll hook to scroll to the top when the route changes
  const router = useRouter();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [router]);

  return <></>;
}

export default ScrollToTop;
