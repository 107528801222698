import {
  NotificationsContainerPropsI,
  NotificationI,
} from "@/interfaces/Notifications";
import { CommentReply } from "./components/commentReply/CommentReply";
import "./NotificationsContainer.scss";
import { useContext, useEffect, useRef } from "react";
import { seenAllNotifications } from "@/services/UserService";
import { useUserContext } from "@/context/UserContext";
import { SocketContext } from "@/context/SocketContext";
import UserStatus from "./components/userStatus/UserStatus";
import General from "./components/general/General";

export default function NotificationsContainer(
  props: NotificationsContainerPropsI
) {
  const {
    notifications,
    show,
    notificationsRef,
    setNotifications,
    setPendingNotifications,
  } = props;
  const { userToken } = useUserContext();
  const socket = useContext(SocketContext);

  const readAllNotifications = async () => {
    seenAllNotifications(userToken).then((res) => {
      if (res && res.status === 200) {
        setNotifications(res.data.notifications);
        setPendingNotifications(false);
      }
    });
  };

  useEffect(() => {
    if (show) {
      readAllNotifications();
    }
  }, [show]);

  useEffect(() => {
    if (socket == null) return;

    socket.on("receive-notification", (notification: NotificationI) => {
      console.log("new notification ");
      setNotifications((prev) => [notification, ...prev]);
      setPendingNotifications(true);
    });

    return () => socket.off("receive-notification");
  }, [socket]);

  function NotificationCard(notification: NotificationI) {
    switch (notification.type) {
      case "commentReply":
      case "discussionReply":
        return (
          <CommentReply notification={notification} key={notification._id} />
        );
      case "userStatus":
        return (
          <UserStatus notification={notification} key={notification._id} />
        );
      case "galleriesPhotosUser":
      case "newContentFromSubs":
        return <General notification={notification} key={notification._id} />;
      // add more cases as needed...
      default:
        return <></>;
    }
  }

  return (
    <div
      className={"notifications-main-container " + (show ? "show-notif" : "")}
      ref={notificationsRef}
    >
      <div className="triangle"></div>
      <div className="notification-title">
        <h3>Notifications</h3>
      </div>
      <div className="actual-notifications-container">
        {notifications.length > 0 ? (
          notifications.map((notification: NotificationI, index) => {
            return NotificationCard(notification);
          })
        ) : (
          <div className="text-center p-2">
            <h3 className="fs-3">You do not have notifications</h3>
          </div>
        )}
      </div>
    </div>
  );
}
