import { ForgotPasswordFormPropsI } from "@/interfaces/AuthFormsInterfaces";

import { object, string } from "yup";
import { useFormik } from "formik";
import "./ForgotPasswordForm.scss";
import { forgotPassword } from "@/services/AuthService";
import { notifyError, notifySuccess } from "@/utils/NotificationFunctions";
import { useState } from "react";

const ArrowLeftIcon = "../../assets/images/icons/arrow-left-icon.svg";
const emailIcon = "../../assets/images/icons/email-icon.svg";
const forgotPasswordSchema = object({
  email: string().email("Invalid email address").required("Email is required"),
});

function ForgotPasswordForm(props: ForgotPasswordFormPropsI) {
  const [loader, setLoader] = useState<boolean>(false);
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, { resetForm }) => {
      setLoader(true);
      let response = await forgotPassword(values.email);
      if (response.status === "Success") {
        notifySuccess(
          "Password reset request successful, check your Email for further instructions"
        );
        resetForm();
      } else {
        notifyError("Unexpected error, please check Email");
      }
      setLoader(false);
    },
  });

  const { errors, touched } = formik;

  return (
    <div className="login-form-main-containerforgot-password">
      <div className="lm-row mb-4">
        <img
          className="go-back-arrow"
          role="button"
          src={ArrowLeftIcon}
          alt="arrow left"
          onClick={() =>
            props.setAuthModal({ ...props.authModal, form: "login" })
          }
        />
      </div>
      <div className="lm-row">
        <h2>Did you forget your password?</h2>
      </div>
      <div className="lm-row">
        <p>
          Input your email address and instructions will be sent to reset your
          password:
        </p>
      </div>
      <form className="login-form" onSubmit={formik.handleSubmit}>
        <div className="form-row">
          <div className="input-container">
            <label htmlFor="email">
              <img src={emailIcon} alt="mail icon" />
            </label>
            <input
              id="email"
              name="email"
              type="email"
              placeholder="Email"
              onChange={formik.handleChange}
              value={formik.values.email}
            />
          </div>
          {touched.email && errors.email && (
            <div className="form-error">{errors.email}</div>
          )}
        </div>
        <div className="form-row">
          <button type="submit" className="btn linear-bg" disabled={loader}>
            Send
          </button>
        </div>
      </form>
    </div>
  );
}

export default ForgotPasswordForm;
