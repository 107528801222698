import {
  fetchPopularSearches,
  searchTextGallery,
} from "@/services/GalleriesService";
import { useEffect, useRef, useState } from "react";
import "./SearchBar.scss";
import CustomSpinner from "../CustomSpinner/CustomSpinner";
import { GalleryI } from "@/interfaces/Galleries";
import { useUserContext } from "@/context/UserContext";
const linkArrow = "../../assets/images/icons/arrow-left.svg";
import { LogI } from "@/interfaces/Logs";
import { createLog } from "@/services/LogService";
import {
  Errors,
  Success,
} from "@/utils/types/res/searchCollection/searchCollection";
import { nameSlug } from "@/utils/Functions/NameSlug";
import { FaSearch } from "react-icons/fa";
import { useRouter } from "next/navigation";

interface SearchBarPropsI {
  show: boolean;
}

function SearchBar(props: SearchBarPropsI) {
  const { show } = props;
  const [searchText, setSearchText] = useState<string>("");
  const [debouncedText, setDebouncedText] = useState<string>("");
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const [galleries, setGalleries] = useState<Array<GalleryI>>([]);
  const [popularSearches, setPopularSearches] = useState<Array<GalleryI>>([]);
  const router = useRouter();
  const { userToken } = useUserContext();

  const { Server_Error } = Errors;
  const { Search_Success } = Success;

  const SearchRef: any = useRef(null);

  const handleChange = (e: any) => {
    setSearchText(e.target.value);
  };

  const getPopularSearches = async () => {
    fetchPopularSearches().then((res) => {
      if (res && res.status === "Success") {
        setPopularSearches(res.data);
      }
    });
  };

  const handleNav = (galleryName: string) => {
    router.push("/" + nameSlug(galleryName));
    setShowSearch(false);
    setSearchText("");
  };

  useEffect(() => {
    getPopularSearches();
  }, []);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedText(searchText);
    }, 500);

    return () => {
      clearTimeout(timerId);
    };
  }, [searchText]);

  useEffect(() => {
    if (debouncedText.length > 2) {
      setLoadingSearch(true);

      searchTextGallery(debouncedText).then((res) => {
        const log: LogI = {
          type: "Search",
          typeNumber: 2,
          visitStart: Date.now(),
          text: debouncedText,
        };
        createLog(userToken, log);
        if (res && res.status === Search_Success) {
          setGalleries(res.data);
          setLoadingSearch(false);
        }
      });
    } else {
      setGalleries([]);
    }
  }, [debouncedText]);

  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      if (SearchRef.current && SearchRef.current.contains(event.target)) {
        setShowSearch(true);
      } else if (
        SearchRef.current &&
        !SearchRef.current.contains(event.target)
      ) {
        setShowSearch(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div
      ref={SearchRef}
      className={
        "search-component-main-container search-container " +
        (show ? "show" : "")
      }
    >
      <div className="input-container">
        <input
          id="search"
          type="text"
          placeholder="Search"
          value={searchText}
          onChange={handleChange}
        />
        <label htmlFor="search">
          <FaSearch />
        </label>
      </div>
      <div
        className={`search-result-container ${showSearch ? "open" : "close"}`}
      >
        {loadingSearch && <CustomSpinner size={8} borderWidth={6} />}
        {!loadingSearch &&
          debouncedText.length > 2 &&
          galleries.length === 0 && (
            <div className="no-result-container">
              <h3>Sorry, we couldn’t find any results matching</h3>
              <h3 className="stem-bold">'{debouncedText}'</h3>
              <h4 className="text-start stem-light">Try:</h4>
              <ul>
                <li className="stem-light text-start">
                  Searching again using more general terms
                </li>
                <li className="stem-light text-start">
                  Exploring the catalog by category
                </li>
              </ul>
            </div>
          )}
        {!loadingSearch && debouncedText.length < 3 && !galleries.length && (
          <div className="popular-searches-container">
            <h3>Popular Searches</h3>
            <div className="popular-searches-btns-container">
              {popularSearches.map((popular) => {
                return (
                  <div
                    className="popular-search"
                    role="button"
                    onClick={() => handleNav(popular.name)}
                    key={popular._id}
                  >
                    <p>{popular?.name}</p>
                    <img src={linkArrow} />
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {!loadingSearch && galleries.length > 0 && (
          <div className="search-galleries-result">
            <h3>Galleries</h3>
            <h4 className="text-center">'{debouncedText}'</h4>
            <div className="galleries-result-cards-container">
              {galleries.map((gallery) => {
                return (
                  <div
                    className="gallery-result-card"
                    role="button"
                    key={gallery._id}
                    onClick={() => handleNav(gallery.name)}
                  >
                    <h4>{gallery.name}</h4>
                    <p>
                      {gallery.photoGallery} available • Created by{" "}
                      {gallery.authorId && gallery.authorId.name
                        ? gallery.authorId.name
                        : "Account deleted"}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SearchBar;
