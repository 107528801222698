import { getImageCode } from "@/services/AuthService";
import { useState, useEffect } from "react";

interface Captcha {
  text: string;
  image: string;
}

function useCaptcha() {
  const [captcha, setCaptcha] = useState<Captcha>({ text: "", image: "" });

  const getCaptcha = async () => {
    await getImageCode()
      .then((res) => {
        setCaptcha({ ...res });
      })
      .catch((error) => {
        throw error;
      });
  };

  useEffect(() => {
    getCaptcha();
  }, []);

  return { captcha, getCaptcha };
}

export default useCaptcha;
