import {
  searchCollection_Errors,
  searchCollection_Success,
} from "@/interfaces/Responses";

export const Errors: searchCollection_Errors = {
  Server_Error: "Internal Server Error",
};
export const Success: searchCollection_Success = {
  Search_Success: "Success",
};
