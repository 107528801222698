import { object, string, ref } from "yup";
import { FaRegCalendarAlt } from "react-icons/fa";
import { useRef, useState } from "react";
import { useFormik } from "formik";
import { SignUpFormPropsI } from "@/interfaces/AuthFormsInterfaces";
import { registerUser } from "@/services/AuthService";
import ReCAPTCHA from "react-google-recaptcha";
import { useUserContext } from "@/context/UserContext";

const emailIcon = "../../assets/images/icons/email-icon.svg";
const userIcon = "../../assets/images/icons/user-icon.svg";
const passwordIcon = "../../assets/images/icons/password-icon.svg";
const eyeSlashIcon = "../../assets/images/icons/eye-slash-icon.svg";
const eyeIcon = "../../assets/images/icons/eye-icon.svg";
const loginSchema = object({
  name: string().required("Name is required"),
  email: string().email("Invalid email adress").required("Email is required"),
  password: string().required("Password is required"),
  passwordConfirm: string()
    .oneOf([ref("password")], "Passwords must match")
    .required("Confirm password is required"),
  captcha: string().required("Captcha validations is required"),
  birthdate: string().required("Birth Date is required"),
});

function SignUpForm(props: SignUpFormPropsI) {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const { referralId, googleApi } = props;
  const { ReactGA } = useUserContext();
  const signUpBtnRef = useRef(null);
  const formik = useFormik({
    initialValues: {
      captcha: "",
      name: "",
      email: "",
      password: "",
      passwordConfirm: "",
      birthdate: "",
      ...(referralId ? { referral: referralId } : {}),
    },
    validationSchema: loginSchema,
    onSubmit: async (values, { resetForm }) => {
      setLoadingSubmit(true);

      await registerUser(values)
        .then((res) => {
          if (res.status === "Error") {
            alert(res.text);
            setLoadingSubmit(false);
          } else {
            ReactGA.event("sign_up");
            alert(
              "Please check your email and spam folder to verify your account."
            );
            setLoadingSubmit(false);
            props.setAuthModal({ show: false, form: "" });
          }
        })
        .catch((error) => {
          console.log(error);
          alert(
            "Oops, something went wrong! Please try again later or contact support for assistance."
          );
        });
    },
  });

  const singUpBtnClicked = () => {
    ReactGA.event("sign_up_click");
  };

  const handleRecaptcha = (value: any) => {
    formik.setFieldValue("captcha", value);
  };

  const { errors, touched } = formik;

  return (
    <div className="login-form-main-container">
      <div className="lm-row">
        {referralId ? (
          <h2>Thank you for using a Referral link!</h2>
        ) : (
          <h2>Register Now!</h2>
        )}
      </div>
      <div className="lm-row">
        {referralId ? (
          <p>
            Create an account and get 500 points, but remember: you have to
            protect it, by gaining 500 points more (only actions with content on
            the platform, like uploading) within the next 7 days. Or you bonus
            will be gone. It's uploading of just 5 pictures, for example. Nail
            it!
          </p>
        ) : (
          <p>
            Sign up to start earning Points that later will be converted into
            our valuable Crypto Coins.
          </p>
        )}
      </div>
      <form className="login-form" onSubmit={formik.handleSubmit}>
        <div className="form-row">
          <div className="input-container">
            <label htmlFor="name">
              <img src={userIcon} alt="user icon" />
            </label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Enter your name"
              onChange={formik.handleChange}
              value={formik.values.name}
            />
          </div>
          {touched.name && errors.name && (
            <div className="form-error">{errors.name}</div>
          )}
        </div>
        <div className="form-row">
          <div className="input-container">
            <label htmlFor="email">
              <img src={emailIcon} alt="mail icon" />
            </label>
            <input
              id="email"
              name="email"
              type="email"
              placeholder="Email"
              onChange={formik.handleChange}
              value={formik.values.email}
            />
          </div>
          {touched.email && errors.email && (
            <div className="form-error">{errors.email}</div>
          )}
        </div>
        <div className="form-row">
          <p>Date of birth</p>
          <div className="input-container">
            <label htmlFor="birthdate">
              <FaRegCalendarAlt className="icon" />
            </label>
            <input
              id="birthdate"
              name="birthdate"
              type="date"
              onChange={formik.handleChange}
              value={formik.values.birthdate}
              style={{ paddingRight: 20 }}
            />
          </div>
          {touched.birthdate && errors.birthdate && (
            <div className="form-error">{errors.birthdate}</div>
          )}
        </div>
        <div className="form-row">
          <div className="input-container">
            <label htmlFor="password">
              <img src={passwordIcon} alt="lock icon" />
            </label>
            <input
              id="password"
              name="password"
              type={!showPassword ? "password" : "text"}
              placeholder="Password"
              onChange={formik.handleChange}
              value={formik.values.password}
            />
            {showPassword ? (
              <img
                className="showPassword"
                onClick={() => setShowPassword(!showPassword)}
                src={eyeIcon}
                alt="eye icon"
              />
            ) : (
              <img
                className="showPassword"
                onClick={() => setShowPassword(!showPassword)}
                src={eyeSlashIcon}
                alt="eye slash icon"
              />
            )}
          </div>
          {touched.password && errors.password && (
            <div className="form-error">{errors.password}</div>
          )}
        </div>
        <div className="form-row">
          <div className="input-container">
            <label htmlFor="passwordConfirm">
              <img src={passwordIcon} alt="lock icon" />
            </label>
            <input
              id="passwordConfirm"
              name="passwordConfirm"
              type={!showPassword ? "password" : "text"}
              placeholder="Confirm password"
              onChange={formik.handleChange}
              value={formik.values.passwordConfirm}
            />
            {showPassword ? (
              <img
                className="showPassword"
                onClick={() => setShowPassword(!showPassword)}
                src={eyeIcon}
                alt="eye icon"
              />
            ) : (
              <img
                className="showPassword"
                onClick={() => setShowPassword(!showPassword)}
                src={eyeSlashIcon}
                alt="eye slash icon"
              />
            )}
          </div>

          {touched.passwordConfirm && errors.passwordConfirm && (
            <div className="form-error">{errors.passwordConfirm}</div>
          )}
        </div>
        <div className="form-row">
          <ReCAPTCHA sitekey={googleApi} onChange={handleRecaptcha} />
          {touched.captcha && errors.captcha && (
            <div className="form-error">{errors.captcha}</div>
          )}
        </div>
        <div className="form-row">
          <button
            type="submit"
            onClick={singUpBtnClicked}
            className={
              "btn linear-bg w-100 " + (loadingSubmit ? "loading" : "")
            }
            disabled={loadingSubmit}
          >
            Sign up
          </button>
        </div>
        <div className="form-row footer-action">
          <h3>
            Already have an account?{" "}
            <span
              role="button"
              onClick={() =>
                props.setAuthModal({ ...props.authModal, form: "login" })
              }
            >
              Sign in
            </span>
          </h3>
        </div>
      </form>
    </div>
  );
}

export default SignUpForm;
