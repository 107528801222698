import { object, string } from "yup";
import { useState } from "react";
import { useFormik } from "formik";
import useCaptcha from "@/hooks/useCaptcha";
import { loginUser } from "@/services/AuthService";
import ReCAPTCHA from "react-google-recaptcha";
import "./LoginForm.scss";
import { validateCaptcha } from "@/services/GoogleService";

const emailIcon = "../../assets/images/icons/email-icon.svg";
const passwordIcon = "../../assets/images/icons/password-icon.svg";
const eyeSlashIcon = "../../assets/images/icons/eye-slash-icon.svg";
const eyeIcon = "../../assets/images/icons/eye-icon.svg";
const loginSchema = object({
  email: string().email("Invalid email adress").required("Email is required"),
  password: string().required("Password is required"),
  captcha: string().required("Captcha is required"),
});

interface LoginFormPropsI {
  authModal: { show: boolean; form: string };
  setAuthModal: React.Dispatch<
    React.SetStateAction<{ show: boolean; form: string }>
  >;
  handleLogin: (token: string) => void;
  googleApi: string;
}

function LoginForm(props: LoginFormPropsI) {
  const { googleApi } = props;
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [loginError, setLoginError] = useState<string>("");
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [captchaResetKey, setCaptchaResetKey] = useState<number>(0);
  const { captcha, getCaptcha } = useCaptcha();

  const formik = useFormik({
    initialValues: {
      captcha: "",
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: async (values, { resetForm }) => {
      setLoadingSubmit(true);

      await loginUser(values)
        .then((res) => {
          if (res.status === "Error") {
            if (
              res.text === "User not found" ||
              res.text === "Invalid password"
            ) {
              setLoginError(
                "*Invalid email or password. Please check your credentials and try again."
              );
            } else {
              setLoginError(res.text);
            }
            setLoadingSubmit(false);
          } else {
            props.handleLogin(res.data);
            props.setAuthModal({ show: false, form: "" });
            setLoadingSubmit(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  });

  // const loginWithGoogle = async () => {
  //   window.location.href = "http://localhost:3025/auth/google";
  // };

  const handleRecaptcha = async (value: any) => {
    if (value) {
      try {
        const res = await validateCaptcha(value);
        if (res.success) {
          formik.setFieldValue("captcha", value);
        } else {
          console.error(
            "Captcha verification failed or suspicious activity detected."
          );
          setCaptchaResetKey((prev) => prev + 1);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const { errors, touched } = formik;
  return (
    <div className="login-form-main-container">
      <div className="lm-row">
        <h2>Welcome back!</h2>
      </div>
      <div className="lm-row">
        <p>Sign in to start exploring the world of digital art</p>
        <p className="login-error">{loginError}</p>
      </div>
      <form className="login-form" onSubmit={formik.handleSubmit}>
        <div className="form-row">
          <div className="input-container">
            <label htmlFor="email">
              <img src={emailIcon} alt="mail icon" />
            </label>
            <input
              id="email"
              name="email"
              type="email"
              placeholder="Email"
              onChange={formik.handleChange}
              value={formik.values.email}
            />
          </div>
          {touched.email && errors.email && (
            <div className="form-error">{errors.email}</div>
          )}
        </div>
        <div className="form-row">
          <div className="input-container">
            <label htmlFor="password">
              <img src={passwordIcon} alt="lock icon" />
            </label>
            <input
              id="password"
              name="password"
              type={!showPassword ? "password" : "text"}
              placeholder="Password"
              onChange={formik.handleChange}
              value={formik.values.password}
            />
            {showPassword ? (
              <img
                className="showPassword"
                onClick={() => setShowPassword(!showPassword)}
                src={eyeIcon}
                alt="eye icon"
              />
            ) : (
              <img
                className="showPassword"
                onClick={() => setShowPassword(!showPassword)}
                src={eyeSlashIcon}
                alt="eye slash icon"
              />
            )}
          </div>

          {touched.password && errors.password && (
            <div className="form-error">{errors.password}</div>
          )}
        </div>
        <div className="form-row forgot-password">
          <h3
            onClick={() =>
              props.setAuthModal({ ...props.authModal, form: "forgotPassword" })
            }
          >
            Forgot password?
          </h3>
        </div>
        <div className="form-row">
          <ReCAPTCHA
            key={captchaResetKey}
            sitekey={googleApi ?? ""}
            onChange={handleRecaptcha}
          />
          {touched.captcha && errors.captcha && (
            <div className="form-error">{errors.captcha}</div>
          )}
        </div>
        <div className="form-row">
          <button
            type="submit"
            className={
              "btn linear-bg w-100 " + (loadingSubmit ? "loading" : "")
            }
            disabled={loadingSubmit}
          >
            Sign in
          </button>
          {/* <button
  					type="button"
  					className={"btn mt-2 google-sign-in-btn linear-bg w-100 " + (loadingSubmit ? "loading" : "")}
  					onClick={loginWithGoogle}
  					disabled={loadingSubmit}
  				>
  					<GoogleLogo className="" />
  					<p className="m-0">Sign in with google</p>
  				</button> */}
        </div>
        <div className="form-row footer-action">
          <h3>
            Don't have an account?{" "}
            <span
              role="button"
              onClick={() =>
                props.setAuthModal({ ...props.authModal, form: "signUp" })
              }
            >
              Sign up
            </span>
          </h3>
        </div>
      </form>
    </div>
  );
}

export default LoginForm;
