import axios from "axios";
import { createLog } from "./LogService";
import { http_request } from "./http/AxiosInstance";
import { notifyError } from "@/utils/NotificationFunctions";
import { LogI } from "@/interfaces/Logs";
import { celebApi } from "./api";
import { ImageState, UploadErrors } from "@/interfaces/Methods";
import { Dispatch, SetStateAction } from "react";

let source: any;

export async function searchTextGallery(search: string) {
  // If there's an ongoing search, cancel it
  if (source) {
    source.cancel("Operation canceled due to new request.");
  }

  // Create a new CancelToken source for this search
  source = axios.CancelToken.source();

  return axios
    .get(`${celebApi}/user/searchCollection`, {
      params: { search },
      cancelToken: source.token,
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      if (axios.isCancel(error)) {
        console.log("Request canceled", error.message);
      } else {
        notifyError("Connection error");
        return error;
      }
    });
}
export async function searchTextGalleryName(search: string) {
  // If there's an ongoing search, cancel it
  if (source) {
    source.cancel("Operation canceled due to new request.");
  }

  // Create a new CancelToken source for this search
  source = axios.CancelToken.source();

  return axios
    .get(`${celebApi}/user/searchCollectionName`, {
      params: { search },
      cancelToken: source.token,
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      if (axios.isCancel(error)) {
        console.log("Request canceled", error.message);
      } else {
        notifyError("Connection error");
        return error;
      }
    });
}

export async function fetchAllGalleriesIds() {
  try {
    const res = await axios.get(`${celebApi}/galleries`);
    return res.data;
  } catch (error) {
    throw error;
  }
}

export async function fetchGalleryByName(name: string) {
  try {
    const res = await axios.get(`${celebApi}/galleries/name`, {
      params: { name: name },
    });
    return res.data;
  } catch (error) {
    throw error;
  }
}

export async function fetchPopularSearches() {
  return axios
    .post(`${celebApi}/user/getMostPopular`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      notifyError("Connection error");
      return error;
    });
}

export async function getGalleryById(id: string) {
  return axios
    .get(`${celebApi}/user/getGallery`, {
      params: { id },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      notifyError("Connection error");
      return error;
    });
}

export async function getRecommend(page: number, excludedIds: Array<string>) {
  return axios
    .post(`${celebApi}/user/getRecomended?page=${page}`, {
      excludedIds: excludedIds,
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      notifyError("Connection error");
      return error;
    });
}

export async function getLastUpdated() {
  return axios
    .get(`${celebApi}/user/getLastUpdated`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
}

export async function fetchMostViewed() {
  return axios
    .get(`${celebApi}/user/getMostViewed`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      notifyError("Connection error");
      throw error;
    });
}

export async function fetchGalleriesSelect() {
  return axios
    .get(`${celebApi}/admin/getGalleriesSelect`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      notifyError("Connection error");
      throw error;
    });
}

export async function fetchFeatured() {
  return axios
    .get(`${celebApi}/admin/getFeatured`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      notifyError("Connection error");
      throw error;
    });
}

export async function updateFeatured(featured: Array<any>, token: string) {
  const httpOptions = {
    headers: {
      Authorization: token,
    },
  };

  return axios
    .put(`${celebApi}/admin/putFeatured/`, { featured }, httpOptions)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return { status: "error", error };
    });
}

export function fetchLargestGalleries() {
  return axios
    .get(`${celebApi}/user/getLargestGalleries/`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      notifyError("Connection error");
      throw error;
    });
}

export async function fetchMostCommented() {
  return axios
    .get(`${celebApi}/user/getMostCommented`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      notifyError("Connection error");
      throw error;
    });
}

export async function fetchGalleries() {
  return axios
    .get(`${celebApi}/user/getCategories`)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      notifyError("Connection error");
      throw error;
    });
}

export async function fetchSpecials() {
  return axios
    .get(`${celebApi}/user/getSpecials`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      notifyError("Connection error");
      throw error;
    });
}

export async function uploadOld(formDataList: Array<FormData>, token: string) {
  // Function to upload images
  const httpOptions = {
    headers: {
      Authorization: token,
    },
  };
  let successCount = 0;
  let errorCount = 0;
  let errorSimilarityCount = 0;
  let errorSimilarityModerateCount = 0;
  const errors: UploadErrors[] = [];
  for (const formData of formDataList) {
    try {
      const response = await axios.post(
        `${celebApi}/user/createImage`,
        formData,
        httpOptions
      );
      const log: LogI = {
        type: "Image Uploaded",
        typeNumber: 6,
        visitStart: Date.now(),
        gallery_name: response.data.data.gallery_name,
        url: response.data.data.url,
      };
      await createLog(token, log);
      successCount++;
    } catch (error: any) {
      if (error.response.status === 409) {
        const err = error.response.data.fileName as string;
        errors.push({ error: err, isModerated: error.response.data.moderated });
        errorSimilarityCount++;
      }
      errorCount++;
    }
  }
  return {
    successCount,
    errorCount,
    errorSimilarityCount,
    errorSimilarityModerateCount,
    errors,
  };
  // const responses = await Promise.all(requests);
  // return responses;
}
export async function upload(formDataList: FormData, token: string) {
  const httpOptions = {
    headers: {
      Authorization: token,
    },
  };
  try {
    const response = await axios.post(
      `${celebApi}/user/uploadMultipleImages`,
      formDataList,
      httpOptions
    );
    // const log: LogI = {
    //   type: "Image Uploaded",
    //   typeNumber: 6,
    //   visitStart: Date.now(),
    //   gallery_name: response.data.gallery_name,
    //   url: response.data.data.url,
    // };
    // await createLog(token, log);
    if (response.data) {
      return {
        successCount: response.data.successCount,
        errorCount: response.data.errorCount,
        errorSimilarityCount: response.data.similarCount,
        errorSimilarityModerateCount: response.data.similarInModerationCount,
        totalFiles: response.data.totalFiles,
        imageAlerts: response.data.imageAlerts,
      };
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
}
export async function newGallery(formData: FormData, token: string) {
  const httpOptions = {
    headers: {
      Authorization: token,
    },
  };

  return axios
    .post(`${celebApi}/user/postCollection`, formData, httpOptions)
    .then(async (response: any) => {
      if (response.status === 200) {
        const log: LogI = {
          type: "Gallery Created",
          typeNumber: 5,
          visitStart: Date.now(),
          gallery_name: response.data.category.name,
        };
        await createLog(token, log);
      }
      return response;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function subscribeGallery(galleryId: string, token: string) {
  const httpOptions = {
    headers: {
      Authorization: token,
    },
  };

  return axios
    .post(`${celebApi}/user/subscribeToGallery`, { id: galleryId }, httpOptions)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return { status: "error", error: error };
    });
}
export async function registerGallerySession(data: any) {
  return axios
    .post(`${celebApi}/user/registerGallerySession`, {
      ...data,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return { status: "error", error: error };
    });
}

export const getTotalGalleries = async () => {
  return http_request.get(`/admin/getTotalGalleries`);
};
