"use client";
import Navbar from "../../components/Navbar/Navbar";
import "./MainLayout.scss";
import { useThemeContext } from "@/context/ThemeContext";

import { ReactNode } from "react";
import { BigShapeTopRight, BigShapeBottomLeft } from "@/assets/imports";
import { PlusIcon } from "@/components/PlusIcon/PlusIcon";
import { QuestionIcon } from "@/components/Questionicon/QuestionIcon";
import ScrollToTop from "@/components/ScrollToTop/ScrollToTop";
import { usePathname } from "next/navigation";
function MainLayout({
  children,
  googleApi,
}: {
  children: ReactNode;
  googleApi: string;
}) {
  const { theme, toggleTheme } = useThemeContext();
  const pathname = usePathname();
  if (pathname.includes("admin")) {
    return <>{children}</>;
  } else {
    return (
      <div
        className={"main-container " + (theme === "dark" ? "dark" : "light")}
      >
        <img src={BigShapeTopRight} className="background-shape top-right" />
        <img
          src={BigShapeBottomLeft}
          className="background-shape bottom-left"
        />
        <ScrollToTop />
        <PlusIcon />
        <QuestionIcon />
        <Navbar theme={theme} toggleTheme={toggleTheme} googleApi={googleApi} />
        {children}
      </div>
    );
  }
}

export default MainLayout;
