import axios from "axios";
import { celebApi } from "./api";

export async function getUserNotifications(userToken: string, userId: string) {
  const httpOptions = {
    headers: {
      Authorization: userToken,
    },
  };
  return axios
    .get(`${celebApi}/user/getUserNotifications/${userId}`, httpOptions)
    .then((res) => {
      return res.data.notifications;
    })
    .catch((error) => {
      return error;
    });
}

export async function setNotificationSeen(
  userToken: string,
  notificationId: string
) {
  const httpOptions = {
    headers: {
      Authorization: userToken,
    },
  };

  return axios
    .post(
      `${celebApi}/user/removeIsNew`,
      { notificationId: notificationId },
      httpOptions
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}
