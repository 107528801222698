import "./AuthModal.scss";
import { Modal } from "react-bootstrap";
import { ReactNode} from "react";

interface AuthModalPropsI {
	show: boolean;
	onHide: () => void;
	theme: string;
	children: ReactNode;
}

function AuthModal(props: AuthModalPropsI) {

	return (
		<Modal className={"auth-modal " + props.theme} show={props.show} onHide={props.onHide} centered>
			<Modal.Header closeButton></Modal.Header>
			<Modal.Body>
				{props.children}
			</Modal.Body>
		</Modal>
	);
}

export default AuthModal;
