import { http_request } from "./http/AxiosInstance";

export const validateCaptcha = async (userResponse: string) => {
	try {
		const response = await http_request.post("google/validateCaptcha", { userResponse });
		return response.data;
	} catch (error) {
		throw error;
	}
};
