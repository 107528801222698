import "./MobileNavBar.scss";
import { useUserContext } from "@/context/UserContext";
import { SetStateAction } from "react";
import { useChatContext } from "@/context/InboxContext";
import Link from "next/link";

const HomeIcon = "../../assets/images/icons/house-icon.svg";
const MessageWhiteIcon = "../../assets/images/icons/message-white-icon.svg";
const NotificationIcon = "../../assets/images/icons/bell-white-icon.svg";
const SearchIcon = "../../assets/images/icons/search-icon.svg";
const UserGrayIcon = "../../assets/images/icons/user-gray-icon.svg";
interface MobileNavBarPropsI {
  show: boolean;
  setShowSearchBar: React.Dispatch<SetStateAction<boolean>>;
  showNotifications: boolean;
  setShowNotifications: React.Dispatch<React.SetStateAction<boolean>>;
  notificationNavBtn: React.RefObject<any>;
  inboxNavBtnRef: React.RefObject<any>;
}

export function MobileNavBar(props: MobileNavBarPropsI) {
  const {
    setShowSearchBar,
    show,
    setShowNotifications,
    showNotifications,
    notificationNavBtn,
    inboxNavBtnRef,
  } = props;
  const { currentUser } = useUserContext();
  const { setOpenInbox, openInbox } = useChatContext();
  return (
    <div className="mobile-navbar-main-container">
      <div className="mobile-navbar-items-container">
        <Link href={"/"}>
          <div className="mn-item-container">
            <img src={HomeIcon} />
          </div>
        </Link>
        <div
          className={"mn-item-container " + (show ? "active" : "")}
          onClick={() => setShowSearchBar((prev) => !prev)}
        >
          <img src={SearchIcon} />
        </div>
        {currentUser && (
          <>
            <div
              className={`mn-item-container ${!showNotifications || "active"}`}
              onClick={() => setShowNotifications(!showNotifications)}
              ref={notificationNavBtn}
            >
              <img src={NotificationIcon} />
            </div>
            <div
              className={`mn-item-container ${!openInbox || "active"}`}
              onClick={() => setOpenInbox(!openInbox)}
              ref={inboxNavBtnRef}
            >
              {" "}
              <img src={MessageWhiteIcon} />
            </div>
            <Link href={"/profile"} role="button">
              <div className="mn-item-container">
                <img src={UserGrayIcon} />
              </div>
            </Link>
          </>
        )}
      </div>
    </div>
  );
}
